(function ($, Drupal) {
  if (
    !(
      site.templates &&
      site.templates.trending_looks_block_article_v1 &&
      site.templates.trending_looks_block_article_v1.content
    )
  ) {
    return;
  }
  let looksModuleBreakpoint = 768;
  let ajaxPromisesArray = [];
  let articleTemplate = site.templates.trending_looks_block_article_v1.content;
  let productsTemplate = `{{# products }}<span class="trending-looks-item-overlay-article-trigger-product"><img alt="" loading="lazy" src="{{ CUSTOM_LARGE_IMAGE }}" /></span>{{/ products }}`;

  let hammertime;

  /**
   * ArticleOverlayCarousel handles the logic of moving between each overlay and opening the right index
   */
  class ArticleOverlayCarousel {
    overlays = [];
    lastActiveIndex = 0;
    register(arrayIndex, articleOverlay) {
      this.overlays[arrayIndex] = articleOverlay;
    }

    open(arrayIndex) {
      let self = this;
      let lastOverlay = this.overlays[this.lastActiveIndex];
      let overlay = this.overlays[arrayIndex];
      let validClosePreviousOverlayCondition = lastOverlay && lastOverlay !== overlay;
      this.lastActiveIndex = parseInt(arrayIndex);
      if (validClosePreviousOverlayCondition) {
        lastOverlay.closingAnimationTimeoutFn(true);
      }
      overlay.open();

      if (Modernizr.touch) {
        let hammertimeCallback = function (event) {
          if (event.deltaX > 0) {
            self.rotatePrevious();
          } else {
            self.rotateNext();
          }
        };
        if (hammertime) {
          hammertime.destroy();
        }
        hammertime = new Hammer(overlay.$articleInfo[0]);
        hammertime.get('swipe').set({
          direction: Hammer.DIRECTION_HORIZONTAL
        });
        hammertime.on('panend', hammertimeCallback);
      }

      $(document)
        .off('keydown.articleOverlay')
        .on('keydown.articleOverlay', (event) => {
          if (event.key === 'Escape') {
            $that.nodes.overlay.close();
          }
          if (event.key === 'ArrowLeft') {
            self.rotatePrevious();
          }
          if (event.key === 'ArrowRight') {
            self.rotateNext();
          }
        });
      overlay.$articleInfo
        .off('click.articleOverlay')
        .on('click.articleOverlay', '.js-trending-looks-block-item-arrow', function (event) {
          if ($(this).index() === 0) {
            self.rotatePrevious();
          } else {
            self.rotateNext();
          }
        });
    }

    rotatePrevious() {
      let previousKey = this.overlays.length - 1;
      if (this.overlays[this.lastActiveIndex - 1]) {
        previousKey = this.lastActiveIndex - 1;
      }
      this.open(previousKey);
    }

    rotateNext() {
      let nextKey = 0;
      if (this.overlays[this.lastActiveIndex + 1]) {
        nextKey = this.lastActiveIndex + 1;
      }
      if (nextKey === this.lastActiveIndex) {
        nextKey = 1;
      }
      this.open(nextKey);
    }
  }

  /**
   * ArticleOverlay represents the full article view when expanded
   */
  class ArticleOverlay {
    articleCarousel;
    priceDisplay = {};
    nid;
    $module;
    $moduleWrapper;
    $articleOverlayStage;
    $articleOverlayStageContentsTarget;
    $articleOverlayStageContentsTargetInstance;
    $articleOverlayContents;
    $articleInfo;
    $articleContents;
    $articleContentsTitle;
    $articleContentsRendered;
    $contentTemplates;
    $articleInfoRenderedCached;
    $articleContentsRenderedCached;
    products;
    openingAnimationTimeoutHolder;
    closingAnimationTimeoutHolder;
    constructor($articleRef, $moduleRef, $contentTemplates, products) {
      this.$module = $moduleRef;
      this.$moduleWrapper = this.$module.closest(
        '.js-trending-looks-block-formatter-background-wrapper'
      );
      this.$background = this.$moduleWrapper.find('.js-trending-looks-block-formatter-background');
      this.$contentTemplates = $contentTemplates;
      this.$articleOverlayContents = $articleRef.find('.js-trending-looks-item-article-revealed');
      this.$articleOverlayStage = $moduleRef.find('.js-trending-looks-overlay-stage');
      this.$articleOverlayStageContentsTarget = this.$articleOverlayStage.find(
        '.js-trending-looks-overlay-stage-content-target'
      );
      this.$articleInfoRenderedCached = $contentTemplates.find(
        '.js-trending-looks-block-item-template-selector-info'
      );
      this.$articleContentsRenderedCached = this.$contentTemplates.find(
        '.js-trending-looks-block-item-template-selector-contents'
      );
      this.products = products;
      this.nid = $articleRef.data('nid');
      this.$module
        .find('.js-trending-looks-block-content-carousel')
        .attr('layout', this.$contentTemplates.data('layout'));
      this.$moduleWrapper.addClass(this.$contentTemplates.data('layout'));
    }

    /**
     * Open article and handle animations, price calculation, add all to bag, and article rendering
     */
    open() {
      // Setup area, before overlay is visible to user
      let self = this;
      if (this.closingAnimationTimeoutHolder) {
        clearTimeout(this.closingAnimationTimeoutHolder);
        this.closingAnimationTimeoutHolder = false;
        this.closingAnimationTimeoutFn();
      }
      // Start animations and displaying article to user below this point
      this.$articleOverlayStage.removeClass('hidden');
      $('html').addClass('trending-looks-item-overlay-opened');
      setTimeout(() => {
        $('html').addClass('trending-looks-item-overlay-animated');
        this.$moduleWrapper.addClass('trending-looks-block-formatter-background-wrapper--active');
      }, 1);
      this.openingAnimationTimeoutHolder = setTimeout(function () {
        $('html').addClass('trending-looks-item-overlay-animated-completed');
      }, 100);
      if (this.$articleOverlayStageContentsTargetInstance) {
        this.$articleOverlayStageContentsTargetInstance.removeClass('hidden');
        var $currentOverlay = self.$articleOverlayStage
          .find('.js-trending-looks-block-item-overlay')
          .not('.hidden');
        var totalPrice = $currentOverlay
          .find('.js-trending-looks-block-item-overlay__content-footer')
          .data('totalPrice');

        // only calculate total price for current overlay if it hasn't been yet
        if (totalPrice) {
          $currentOverlay
            .find('.js-trending-looks-block-item-overlay-content-footer-label-price')
            .html(`${self.$module.data('totalPriceLabel')}${totalPrice}`);
        } else {
          self.priceHandler();
        }
        return;
      }
      // First time being initiated
      this.$articleOverlayStageContentsTarget.append(this.$articleOverlayContents.html());
      this.$articleOverlayStageContentsTargetInstance = this.$articleOverlayStageContentsTarget
        .children()
        .last();
      this.$articleInfo = this.$articleOverlayStageContentsTargetInstance.find(
        '.js-trending-looks-item-overlay-info'
      );
      this.$articleContents = this.$articleOverlayStageContentsTargetInstance.find(
        '.js-trending-looks-item-overlay-content'
      );
      this.$articleContentsTitle = this.$articleContents.find(
        '.js-trending-looks-item-overlay-content-title'
      );
      this.$articleContentsRendered = this.$articleContents.find(
        '.js-trending-looks-item-overlay-content-rendered'
      );
      this.$addAllToBag = this.$articleContents.find('.js-product-add-all-to-bag');
      this.$articleContentsTitle.html(this.$contentTemplates.data('title'));
      this.$articleContentsRendered.html(this.$articleContentsRenderedCached);
      this.$articleInfo.html(this.$articleInfoRenderedCached);
      this.$articleInfo
        .find('.js-trending-looks-block-item-arrow.slick-prev')
        .attr('aria-label', site.translations.elc_general.previous);
      this.$articleInfo
        .find('.js-trending-looks-block-item-arrow.slick-next')
        .attr('aria-label', site.translations.elc_general.next);
      self.priceHandler();
      self.$background.off('click.close').on('click.close', function () {
        self.close();
      });
      self.$articleOverlayStage.off('click.close').on('click.close', function (event) {
        if (event.target === self.$articleOverlayStage[0]) {
          self.close();
        }
      });
      self.$articleOverlayStage
        .off('product.skuSelect')
        .on('product.skuSelect', '.js-product-ui', function (e) {
          self.priceHandler(true);
        });
    }

    // Remove events and add animations for closing the overlay
    close() {
      let self = this;
      if (this.openingAnimationTimeoutHolder) {
        clearTimeout(this.openingAnimationTimeoutHolder);
      }
      let keepHtmlActive =
        $('html').find('.trending-looks-block-formatter-background-wrapper--active').length > 1;
      $('html').toggleClass('trending-looks-item-overlay-animated-closing', !keepHtmlActive);

      setTimeout(function () {
        self.$moduleWrapper.removeClass(
          'trending-looks-block-formatter-background-wrapper--active'
        );

        $('html').toggleClass(
          'trending-looks-item-overlay-animated trending-looks-item-overlay-animated-completed',
          keepHtmlActive
        );

        this.closingAnimationTimeoutHolder = setTimeout(() => {
          $('html').toggleClass(
            'trending-looks-item-overlay-opened trending-looks-item-overlay-animated-closing',
            keepHtmlActive
          );
          self.$articleOverlayStage.addClass('hidden');
          self.closingAnimationTimeoutFn();
        }, 550);
        $(document).off('keydown.articleOverlay click.keydown');
      }, 1);
      self.$articleOverlayStage.off('click.close');
    }

    /**
     * Calculate total price on the FE and update text.
     */
    priceHandler(disableInit) {
      let self = this;
      let $currentOverlay = self.$articleOverlayStage
        .find('.js-trending-looks-block-item-overlay')
        .not('.hidden');
      self.calculatePrice().then((priceDisplay) => {
        if (!disableInit) {
          $(document).trigger('productBrief.init');
          $(document).trigger('productBriefShades.init');
        }
        // store the total price on the current overlay
        $currentOverlay
          .find('.js-trending-looks-block-item-overlay__content-footer')
          .data('totalPrice', priceDisplay);
        self.$articleOverlayStage
          .find('.js-trending-looks-block-item-overlay-content-footer-label-price')
          .html(`${self.$module.data('totalPriceLabel')}${priceDisplay}`);
        self.$articleOverlayStage
          .find('.js-trending-looks-block-item-overlay-content-footer-label-footnote')
          .css('color',`${self.$module.data('totalPriceLabelColor')}`)
          .html(`${self.$module.data('totalPriceLabelFootnote')}`)
          .addClass(self.$module.data('totalPriceLabelFont') ? `${self.$module.data('totalPriceLabelFont')}` : '');
        self.setupAddAllToBag();
      });
    }

    /** Close this article only. */
    closingAnimationTimeoutFn() {
      this.$articleOverlayStageContentsTargetInstance
        ? this.$articleOverlayStageContentsTargetInstance.addClass('hidden')
        : null;
    }

    setupAddAllToBag() {
      let allSkuIds = [];
      this.$articleContentsRendered.find('.js-product-brief').each(function () {
        let $productBrief = $(this);
        let $shadeSelected = $productBrief.find('.js-product-brief-shades-item-link.selected');
        let $targetSkuElement = $shadeSelected.length > 0 ? $shadeSelected : $productBrief;
        allSkuIds.push($targetSkuElement.attr('data-sku-base-id'));
      });
      this.$addAllToBag.removeClass('hidden').attr('data-sku-base-id', allSkuIds.join(', '));
    }

    /**
     * Calculate prices on the FE based on active SKU. Promise based, so it can be converted to API call at some point later.
     */
    calculatePrice() {
      return new Promise((resolve, reject) => {
        let prices = [];
        let overlayPrice;
        let totalPrice;
        this.$articleContentsRendered.find('.js-product-brief').each(function () {
          let skuId = $(this).attr('data-sku-base-id');
          totalPrice = Drupal.settings.display_totalprice_withtax
            ? Drupal.settings.display_totalprice_withtax
            : false;
          if (totalPrice) {
            var formattedPrice = prodcat.data.getSku(skuId).formattedPrice;
            var pricewithTax = parseFloat(formattedPrice.replace(/\$|,/g, '.'));
            prices.push(pricewithTax);
          } else {
            prices.push(prodcat.data.getSku(skuId).PRICE);
          }
        });
        overlayPrice = Drupal.settings.trending_look_overlay_price
          ? Drupal.settings.trending_look_overlay_price
          : false;
        if (overlayPrice) {
          return resolve(prices.reduce((price1, price2) => price1 + price2));
        }
        return resolve(prices.reduce((price1, price2) => price1 + price2).toFixed(2));
      });
    }
  }

  Drupal.behaviors.trendingLooksBlockFormatterV1 = {
    itemInlineTemplatePath: 'hotspot_text_v2',
    isMobile: false,
    attach: function (context) {
      let self = this;
      let $module = $('.js-trending-looks-block-formatter--v1', context);

      $module.each(function () {
        let $this = $(this);
        self.getDom($this);
        self.setSettings($this);
        self.setup($this);
        self.setEvents($this);
      });
    },

    carouselFn: function ($that) {
      const self = this;
      const referenceCount = $that.nodes.$references.length;
      if ($(window).width() < looksModuleBreakpoint && !$that.settings.isMultiLooks) {
        $that.nodes.$blockContentCarousel.slick({
          mobileFirst: true,
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          appendDots: $that.nodes.$dots,
          arrows: $that.settings.sliderConfig.arrows,
          centerPadding: '20px'
        });
      } else if (!$that.settings.isMultiLooks) {
        /**
         * Desktop design, which is similar to a carousel but with custom transition and only 3 slots
         */
        let primaryIndex = 0;
        let $originalReferencesOrder = $that.nodes.$references.clone();
        $that.nodes.$arrows
          .off('click.rotateIntroCarousel')
          .on('click.rotateIntroCarousel', function () {
            primaryIndex++;
            if (!$originalReferencesOrder.eq(primaryIndex).length) {
              primaryIndex = 0;
            }
            $that.nodes.$blockContentCarousel.addClass('rotated');
            $that.nodes.$references.each(function (index) {
              let $this = $(this);
              let $newElement;
              let searchIndex = (primaryIndex + index) % referenceCount;
              if (!$originalReferencesOrder.eq(searchIndex).length) {
                primaryIndex = 0;
                searchIndex = primaryIndex + index;
              }
              $newElement = $originalReferencesOrder
                .eq(searchIndex)
                .addClass('initialized')
                .clone();

              $this.parent().append($newElement);
              setTimeout(function () {
                $that.nodes.$blockContentCarousel.addClass('transition');
                $newElement.addClass('transition display-title');
                setTimeout(function () {
                  $newElement.removeClass('initialized transition');
                  // Remove middle slide to prevent infinite clones
                  if ($this.parent().children().length > 2) {
                    $this.parent().children().eq(1).remove();
                  }
                }, 1000);
              }, 1);
            });
          });
      }
      if ($that.settings.isMultiLooks) {
        $that.nodes.$blockContentCarousel
          .once('slider-peeking-changed')
          .on('slider-peeking-changed', function (e, slick) {
            if (typeof slick.setPosition === 'function') {
              setTimeout(function () {
                slick.setPosition();
              }, 100);
            }
          });
        self.initSlider($that);
      }
    },
    setArrowsColor: function ($that) {
      $that.nodes.$carouselArrows
        .find('.slick-prev')
        .css('background-color', $that.nodes.$carouselArrows.data('color'));
      $that.nodes.$carouselArrows
        .find('.slick-next')
        .css('background-color', $that.nodes.$carouselArrows.data('color'));
    },

    setup: function ($that) {
      const self = this;
      /**
       * Init mobile carousel design
       */
      $that.nodes.$articles.each(function () {
        $(this).find($that.nodes.$references).length > 0 ? $(this).addClass('slick-item') : null;
      });
      /**
       * Loop the nodes, get the structured JSON data
       */
      if (!$that.settings.isCarousel) {
        $that.addClass('no-carousel');
      }
      self.setArrowsColor($that);
      self.initReferences($that);
    },

    initReferences: function ($that) {
      const self = this;
      $that.nodes.$references.each(function (index) {
        let $ref = $(this);
        let nid = $ref.data('nid');
        let ajaxPromise = new Promise((resolve, reject) => {
          $.ajax({
            url: '/elc_api/node/' + nid,
            success: function (data) {
              let products = [];
              let rendered;
              $that.settings.allRendered[index] = $(data['rendered']);
              for (tpl of data[nid].template_fields) {
                if (tpl.data.step_product) {
                  products.push(tpl.data.step_product.product_id.product_id);
                }
                if (tpl.data.look_products) {
                  let cleanedProducts = tpl.data.look_products.product_id.product_id.split(',');
                  products = cleanedProducts;
                }
                if (tpl.data._article_cover_media_field) {
                  let mediaVideoData = tpl.data._article_cover_media_field.video
                    ? tpl.data._article_cover_media_field.video.youtube.youtube
                    : null;
                  let mediaImageData = tpl.data._article_cover_media_field.image
                    ? tpl.data._article_cover_media_field.image.images.desktop_image.fid
                    : null;
                  let hasMediaVideo = mediaVideoData ? true : false;
                  let hasMediaImage = mediaImageData ? true : false;
                  tpl.data.has_media = mediaVideoData || mediaImageData ? true : false;
                  tpl.data._article_cover_media_field.has_media_video = hasMediaVideo;
                  tpl.data._article_cover_media_field.has_media_image = hasMediaImage;
                }
                if (!!tpl.data.show_item_info_on_article) {
                  tpl.data.show_item_info_on_article = tpl.data.show_item_info_on_article.checkbox
                    ? tpl.data.show_item_info_on_article.checkbox.value
                    : tpl.data.show_item_info_on_article;
                }
              }
              // Render each full article display on the frontend
              rendered = Mustache.render(articleTemplate, {
                ...{
                  articles: Array.isArray(data[nid].template_fields)
                    ? data[nid].template_fields.slice(0, 1)
                    : [data[nid].template_fields],
                  products_count: products.length
                },
                ...site.templates.trending_looks_block_article_v1.data
              });
              $ref.append(rendered);
              $that.settings.allProductsNeeded = [...$that.settings.allProductsNeeded, ...products];
              $that.settings.productsNeededPerArticle[index] = products;
              $ref.find('.js-trending-looks-item-article-trigger').attr('data-look-index', index);
              resolve();
              for (tpl of data[nid].template_fields) {
                if (tpl.data.text_highlighting) {
                  let hotspotData = tpl.data;
                  let $refDescription = $ref.find(
                    '.js-trending-looks-item-article-trigger__item-description'
                  );
                  $ref.trigger('hotspot.enable', [hotspotData, $refDescription]);
                }
                if (tpl.data._article_cover_media_field) {
                  let $video = $ref.find('.js-videojs-player');
                  let $videoWrapper = $video.closest('.js-videojs-video');
                  let $videoInner = $video.length > 0 ? $video.children() : null;
                  let ytID = tpl.data._article_cover_media_field.video
                    ? tpl.data._article_cover_media_field.video.youtube.youtube
                    : null;
                  $videoInner && $videoInner.length > 0 && ytID
                    ? $videoInner.attr(
                        'data-setup',
                        `{"fluid":true,"techOrder":["youtube"],"sources":[{"type":"video\/youtube","src":"https:\/\/www.youtube.com\/watch?v=` +
                          ytID +
                          `&rel=0"}]}`
                      )
                    : null;
                  new MantleVideo.ELCVideoJS($videoWrapper);
                }
              }
            },
            error: function () {
              reject();
            }
          });
        });
        ajaxPromisesArray.push(ajaxPromise);
      });
      /**
       * Once our refs are setup, fetch required products and render their display cards
       */
      Promise.all(ajaxPromisesArray).then(() => {
        generic.jsonrpc.fetch({
          method: 'prodcat',
          params: [
            {
              products: $that.settings.allProductsNeeded,
              sku_fields: [
                'PRODUCT_CODE',
                'SKU_BASE_ID',
                'SKU_ID',
                'SHADENAME',
                'formattedPrice',
                'formattedPrice2',
                'formattedUnitPrice',
                'PRICE',
                'PRICE2',
                'IMAGE_MEDIUM',
                'IMAGE_LARGE',
                'PRODUCT_ID',
                'INVENTORY_STATUS',
                'DISPLAY_STATUS',
                'LIFE_OF_PRODUCT',
                'DEFAULT_SKU',
                'highVelocityQty',
                'isShoppable',
                'isOrderable',
                'isLimitedRemaining',
                'isComingSoon'
              ],
              product_fields: [
                'PRODUCT_ID',
                'PROD_BASE_ID',
                'DISPLAY_STATUS',
                'shaded',
                'sized',
                'TOTAL_REVIEW_COUNT',
                'AVERAGE_RATING',
                'AVERAGE_RATING_PERCENT',
                'AVERAGE_RATING_ROUNDED',
                'MISC_FLAG_TEXT',
                'MISC_FLAG',
                'isShoppable',
                'PRODUCT_TYPE',
                'skus'
              ]
            }
          ],
          onBoth: function (r) {
            let skus = [];
            let skusAsProduct = [];
            let tmpItem = {};
            let products = [];
            let productsData;
            let rendered;
            // Account for differences between jsonrpc libraries, i.e. FredericMalle
            if (typeof r.getValue === 'function') {
              productsData = r.getValue().products;
            } else {
              productsData = r.result.value.products;
            }
            if (productsData.length < 1) {
              return;
            }
            productsData.filter(item => !!item).forEach((item) => {
              if (item.SKU_BASE_ID) {
                skus.push(item);
                tmpItem = $.extend({}, item);
                // Remove the fields that are not supposed to override product values.
                delete tmpItem.IMAGE_MEDIUM;
                delete tmpItem.IMAGE_LARGE;
                skusAsProduct.push(tmpItem);
              } else {
                products.push(item);
              }
            });
            prodcat.data.updateSkus(skus);
            prodcat.data.updateProducts(skusAsProduct);
            prodcat.data.updateProducts(products);
            // Loop article cards once required data is ready to display
            self.loadReferences($that);
          },
          onFailure: function (e) {}
        });
      });
    },
    loadReferences: function ($that) {
      var self = this;
      $that.nodes.$references.each(function (index) {
        let $ref = $(this);
        let productsData = [];
        let theseProductsNeeded = $that.settings.productsNeededPerArticle[index];
        theseProductsNeeded.forEach((prodId) => {
          let productData;
          if (prodId.includes('SKU')) {
            productData = prodcat.data.getSku(prodId);
          } else {
            productData = prodcat.data.getProduct(prodId);
          }
          if (!productData) {
            return;
          }
          if (productData.IMAGE_MEDIUM?.length > 0 ) {
            productData.CUSTOM_LARGE_IMAGE = productData.IMAGE_MEDIUM[0];
          } else if (productData.defaultSku?.IMAGE_MEDIUM?.length > 0) {
            productData.CUSTOM_LARGE_IMAGE = productData.defaultSku.IMAGE_MEDIUM[0];
          } else if (productData.skus && productData.skus[0] && productData.skus[0].IMAGE_MEDIUM?.length > 0) {
            productData.CUSTOM_LARGE_IMAGE = productData.skus[0].IMAGE_MEDIUM[0];
          }
          productsData.push(productData);
        });
        rendered = Mustache.render(productsTemplate, {
          products: [...new Set(productsData)].slice(0, $that.data('productDisplay')) // get unique products values by converting to a new set
        });
        $ref.find('.js-trending-looks-item-article-trigger-footer-products').html(rendered);
        $ref.find('.js-trending-looks-item-article-trigger').attr('data-look-index', index);
        $that.nodes.overlay = new ArticleOverlay(
          $ref,
          $that,
          $($that.settings.allRendered[index]).find(
            '.js-trending-looks-block-item-template-selector'
          ),
          productsData
        );
        let itemLayout =
          $that.nodes.$blockContentCarousel && $that.nodes.$blockContentCarousel.attr('layout');
        let $video = $ref.find('.js-trending-looks-item-article-trigger__video');

        $video.length > 0 && $video.children().length == 0 ? $video.addClass('hidden') : null;
        !self.isMobile && itemLayout
          ? $that.nodes.$blockContentCarousel.addClass(itemLayout)
          : null;
        $that.settings.articleOverlayCarouselController.register(
          $ref.find('.js-trending-looks-item-article-trigger').attr('data-look-index'),
          $that.nodes.overlay
        );
      });
      if ($that.settings.isCarousel) {
        self.carouselFn($that);
        $that.nodes.$carouselArrows.length > 0 ? self.setArrowsColor($that) : null;
      }
    },

    setEvents: function ($that) {
      const self = this;
      $that
        .off('click.triggerArticle')
        .on('click.triggerArticle', '.js-trending-looks-item-article-trigger', function (e) {
          // prevent triggering the overlay if the click is on the hotspot
          if (
            (!$that.settings.isMultiLooks && !$(e.target).parent().is('a')) ||
            ($that.settings.isMultiLooks &&
              $(e.target).parent().is('.trending-looks-item-article-trigger__footer-cta'))
          ) {
            e.preventDefault();
            $that.settings.articleOverlayCarouselController.open(
              $(this).closest('[data-look-index]').attr('data-look-index')
            );
          }
        });
      $that
        .off('click.closeArticleStage')
        .on('click.closeArticleStage', '.js-trending-looks-overlay-stage-close', (e) => {
          e.preventDefault();
          $that.nodes.overlay.close();
        });
      Unison.on('change', function () {
        self.isMobile = self.checkIfMobile();
        self.setSettings($that);
      });
    },
    getDom: function ($that) {
      $that.nodes = {};
      $that.nodes.overlay;
      $that.nodes.$references = $('.js-trending-looks-block-reference--v1', $that);
      $that.nodes.$articles = $('.js-trending-looks-block__content-article', $that);
      $that.nodes.$carouselArrows = $('.js-trending-looks-block__intro-slick-arrows', $that);
      $that.nodes.$arrows = $('.js-trending-looks-block-arrow', $that);
      $that.nodes.$dots = $('.js-trending-looks-dots', $that);
      $that.nodes.$blockContentCarousel = $('.js-trending-looks-block-content-carousel', $that);
    },

    checkIfMobile: function () {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

      return currentBpWidth <= bPlargeUp;
    },
    setSettings: function ($that) {
      var self = this;

      $that.settings = {};
      ($that.settings.articleOverlayCarouselController = new ArticleOverlayCarousel()),
        ($that.settings.allRendered = []);
      $that.settings.allProductsNeeded = [];
      $that.settings.productsNeededPerArticle = {};
      $that.settings.isCarousel = $that.nodes.$references.length > 1;
      $that.settings.isMultiLooks = $that.closest('.js-multi-looks').length > 0;
      $that.settings.direction = $('html').attr('dir') || 'ltr';
      $that.settings.breakpoints = $that.nodes.$blockContentCarousel.data('breakpoints') || {};
      $that.settings.sliderConfig = $that.nodes.$blockContentCarousel.data('sliderConfig') || {};
      $that.settings.sliderConfig.dots = $that.settings.sliderConfig.dots > 0 ? true : false;
      $that.settings.sliderConfig.arrows = $that.settings.sliderConfig.arrows > 0 ? true : false;
      $that.settings.sliderConfig.infinite =
        $that.settings.sliderConfig.infinite > 0 ? true : false;

      if (!!$that.settings.sliderConfig && !!$that.settings.sliderConfig.peeking) {
        self.setPeeking($that);
      }
    },
    initSlider: function ($that) {
      var self = this;
      $that.nodes.$blockContentCarousel.each(function () {
        var $this = $(this);
        var hasPeeking =
          !!$that.settings.sliderConfig && !!$that.settings.sliderConfig.peekingPercent;

        var settings = {
          slide: '.slick-item',
          dots: true,
          appendDots: $that.nodes.$dots,
          arrows: $that.settings.sliderConfig.arrows,
          appendArrows: $that.nodes.$carouselArrows,
          infinite:
            hasPeeking && $that.settings.sliderConfig.peekingPercent.mobile > 1
              ? true
              : $that.settings.sliderConfig.infinite,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: $that.settings.breakpoints.desktop || 1
              }
            },
            {
              breakpoint: 391,
              settings: {
                slidesToShow: $that.settings.breakpoints.mobile || 1
              }
            }
          ],
          onInit: function (slick) {
            self.togglePeekingClasses($that, slick);
            $that.nodes.$blockContentCarousel.trigger('slider-initialised');
          },
          onAfterChange: function () {
            $that.nodes.$blockContentCarousel.trigger('slider-changed--after');
            $that.nodes.$blockContentCarousel.removeClass('slick-sliding');
          }
        };

        // Init this carousel with our settings
        $this.slick(settings);
      });
    },
    setPeeking: function ($that) {
      $that.settings.sliderConfig.peekingPercent = {};
      Object.keys($that.settings.breakpoints).forEach(function (key) {
        $that.settings.breakpoints[key] = parseInt($that.settings.breakpoints[key]);
        if (
          typeof $that.settings.sliderConfig.peeking[key] === 'string' &&
          $that.settings.sliderConfig.peeking[key].indexOf('.') === 0
        ) {
          $that.settings.sliderConfig.peekingPercent[key] =
            ((100 / $that.settings.breakpoints[key]) *
              parseInt($that.settings.sliderConfig.peeking[key].replace('.', ''))) /
            100;
        }
      });
    },
    getBreakpoint: function () {
      var width = parseInt(Unison.fetch.now().width, 10);
      if (width <= parseInt(Unison.fetch.all()['usn-medium'], 10)) {
        return 'mobile';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xxx-large'], 10)) {
        return 'desktop';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xwide'], 10) > 0) {
        return 'large';
      }
      return 'extra_large';
    },

    togglePeekingClasses: function ($that, slick) {
      var self = this;
      if ($that.settings.sliderConfig.length < 1 || !$that.settings.sliderConfig.peekingPercent) {
        return;
      }
      var direction = $that.settings.direction === 'ltr' ? 'right' : 'left';
      var breakpoint = self.getBreakpoint();
      var hasSlides =
        $that.settings.breakpoints[breakpoint] <
        $that.nodes.$blockContentCarousel.find('.slick-item').length;

      var percent = $that.settings.sliderConfig.peekingPercent[breakpoint] || 0;
      if (percent > 0 && hasSlides) {
        percent = self.closestNumber(percent, 5) || 5;
        $that.nodes.$blockContentCarousel
          .find('.slick-list')
          .addClass('padding-' + direction + '-' + percent);
        $that.nodes.$blockContentCarousel.trigger('slider-peeking-changed', slick);
      }
    },

    // Find closest number to n divisible by m
    closestNumber: function (n, m) {
      let q = parseInt(n / m);
      let n1 = m * q;
      let n2 = n * m > 0 ? m * (q + 1) : m * (q - 1);
      if (Math.abs(n - n1) < Math.abs(n - n2)) {
        return n1;
      } else {
        return n2;
      }
    }
  };
})(jQuery, Drupal);
